import React from "react";

const BackgroundSection = () => {
    return (
        <div className="relative h-screen bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: "url('./images/background.png')" }}>
         
            <div className="absolute inset-0 bg-black opacity-70"></div>
         
            <div className="relative z-5 flex items-center justify-center h-full w-full px-4">
                <h2 className="text-white text-[24px] sm:text-[32px] md:text-[36px] lg:text-[40px] mx-auto font-normal leading-8 sm:leading-10 md:leading-12 text-center font-poppins max-w-4xl">
                    <span className="text-mainColor font-bold">"Misr Print"</span> is an Egyptian company with 40 years of experience in producing high-quality steel plates for pad printing. We specialize in manufacturing both thin and thick steel clichés for various industries, focused on delivering durable, customizable, and precise steel clichés for our diverse client base.
                </h2>
            </div>
        </div>
    );
};

export default BackgroundSection;
