import React from 'react'
import { Link } from 'react-router-dom'
import { SiFacebook } from "react-icons/si";
import { FaTwitter } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";

import { SiLinkedin } from "react-icons/si";
import { SiInstagram } from "react-icons/si";

export default function Footer() {
  return (
    <footer className="px-3 pt-[80px] pb-[40px] lg:px-9 border-t-2 bg-footerColor">
    <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">



        <div className="sm:col-span-2">

            <div className="inline-flex items-center">
            <Link to="/" className="flex items-center">
          <img
            src="/images/logo.png"
            alt="MyLogo"
            className="h-[60px] w-[60px] object-contain"
          />
          <span className="ms-[-18px] text-[22px] font-normal text-mainColor">Misr Print</span>
        </Link>
                    </div>
            <div className="mt-6 lg:max-w-xl">
                <p className="text-sm text-white text-[16px]   font-poppins">
                We are a company with 40 years of experience in producing high-quality steel plates for pad printing 
                    </p>   
            </div>
            <div className="flex space-x-4 pt-[35px]">
  <h3 className="text-white font-poppins">Follow us</h3>
  <ul className="flex space-x-5">
    <li>
      <Link  to="https://www.facebook.com/mostafa.mahmoud.56614?mibextid=LQQJ4d&rdid=ihLt2WNQKrj7qdQu&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F18Qa93CUWN%2F%3Fmibextid%3DLQQJ4d" target="_blank">
        <SiFacebook className="text-blue-600 hover:text-blue-800" />
      </Link>
    </li>
    <li>
      <Link  to="https://www.linkedin.com/in/mostafa-darwish-4b9198241/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank">
        <SiLinkedin className="text-blue-700 hover:text-blue-900" />
      </Link>
    </li>
    <li>
  <a href="https://wa.me/+201005178711" target="_blank" rel="noopener noreferrer">
    <FaWhatsapp className="text-green-500 hover:text-green-600" />
  </a>
</li>

  </ul>
</div>


        </div>




        <div className="flex flex-col gap-2 text-sm text-white  font-poppins">
            <p className="text-base font-bold tracking-wide">Our Categories</p>
            
            <Link to="about">About us</Link>
            <Link to="news" >News</Link>
            <Link to="products">Products</Link>
            <Link to="Services" >Services</Link>
            <Link to="industriesweserve">Industries we serve</Link>
            
        </div>


        <div className="text-white  font-poppins">
  <p className="text-base font-bold tracking-wide text-white text-[18px]  font-poppins">
    We Are here always for help you
  </p>
  <div className="flex items-center gap-2 px-2">
    <div className="h-full flex items-center">
      <i className="fa-regular fa-envelope-open md:text-[38px] sm:text-[32px] text-mailColor"></i>
    </div>
    <div className='my-[20px]'>
  <p className="text-[16px] text-mailColor">E-mail Support</p>
  <a href="mailto:info@misrprinting.com" className="text-[18px] text-mailColor">
    info@misrprinting.com
  </a>
</div>

  </div>
  <div className="flex items-center gap-2 px-2">
    <div className="h-full flex items-center">
      <i className="fa-solid fa-phone md:text-[38px] sm:text-[32px] text-phoneColor"></i>
    </div>
    <div>
  <p className="text-[16px] text-phoneColor">Phone Support</p>
  <a href="tel:+201005178711" className="text-[18px] text-phoneColor">
    +201005178711
  </a>
</div>

  </div>
</div>


    </div>

 
 
</footer>
  )
}
