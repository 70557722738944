import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  
  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
    if (!isOpen) {
      // Disable scrolling when menu is open
      document.body.style.overflow = "hidden";
      document.body.style.zIndex = "100";
      
    } else {
      // Re-enable scrolling when menu is closed
      document.body.style.overflow = "auto";
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
    // Re-enable scrolling when menu is closed
    document.body.style.overflow = "auto";
  };

  return (
    <nav className="bg-footerColor shadow-md relative ">
      <div className="container mx-auto flex items-center justify-between p-4 ">
      
        <Link to="/" className="flex items-center">
          <img
            src="/images/logo.png"
            alt="MyLogo"
            className="h-[60px] w-[60px] object-contain"
            onClick={closeMenu}
          />
          <span className="ms-[-18px] text-[22px] font-normal text-mainColor"  onClick={closeMenu}>
            Misr Print
          </span>
        </Link>

        
        <button
          className="md:hidden flex flex-col items-center justify-center w-8 h-8 z-50 overflow-hidden"
          onClick={toggleMenu}
        >
          <span className="block w-6 h-[2px] bg-white mb-1"></span>
          <span className="block w-6 h-[2px] bg-white mb-1"></span>
          <span className="block w-6 h-[2px] bg-white"></span>
        </button>

        <ul
          className={`absolute md:relative bg-footerColor md:bg-transparent w-full md:w-auto left-0 md:left-auto top-14 md:top-0 z-10 md:flex md:space-x-6 
          transition-all duration-300 ease-in-out ${
            isOpen ? "block" : "hidden"
          }`}
        >
          <li className="nav-item">
            <Link
              to="/about"
              className={`block py-2 px-4 md:inline md:px-0 ${
                currentPath === "/about"
                  ? "text-mainColor font-semibold"
                  : "text-mailColor"
              }`}
              onClick={closeMenu} 
            >
              About Us
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/news"
              className={`block py-2 px-4 md:inline md:px-0 ${
                currentPath === "/news"
                  ? "text-mainColor font-semibold"
                  : "text-mailColor"
              }`}
              onClick={closeMenu} 
            >
              News
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/products"
              className={`block py-2 px-4 md:inline md:px-0 ${
                currentPath === "/products"
                  ? "text-mainColor font-semibold"
                  : "text-mailColor"
              }`}
              onClick={closeMenu} 
            >
              Products
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/services"
              className={`block py-2 px-4 md:inline md:px-0 ${
                currentPath === "/services"
                  ? "text-mainColor font-semibold"
                  : "text-mailColor"
              }`}
              onClick={closeMenu} 
            >
              Services
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/industriesweserve"
              className={`block py-2 px-4 md:inline md:px-0 ${
                currentPath === "/industriesweserve"
                  ? "text-mainColor font-semibold"
                  : "text-mailColor"
              }`}
              onClick={closeMenu} 
            >
              Industries We Serve
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/clienttestimonials"
              className={`block py-2 px-4 md:inline md:px-0 ${
                currentPath === "/clienttestimonials"
                  ? "text-mainColor font-semibold"
                  : "text-mailColor"
              }`}
              onClick={closeMenu} 
            >
              Client Testimonials
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact"
              className={`block py-2 px-4 md:inline md:px-0 ${
                currentPath === "/contact"
                  ? "text-mainColor font-semibold"
                  : "text-mailColor"
              }`}
              onClick={closeMenu} 
            >
              Contact Us
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/faq"
              className={`block py-2 px-4 md:inline md:px-0 ${
                currentPath === "/faq"
                  ? "text-mainColor font-semibold"
                  : "text-mailColor"
              }`}
              onClick={closeMenu} 
            >
              FAQ
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
