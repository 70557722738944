import React from 'react'

export default function News() {
  return (
    <div className='mx-4 md:mx-[120px] mt-[40px]'>
      <h2 className='font-poppins text-[32px] sm:text-[28px] md:text-[32px] text-titleColor font-medium mb-4'>Hot Topics</h2>

    
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-12 gap-4 mb-[70px] ">

        
        <div className="col-span-1 md:col-span-8">
          <div className="relative bg-cover bg-center w-full h-[300px] sm:h-[400px] md:h-[500px]"
            style={{
              backgroundImage: "url('./images/newsHeader.png')",
            }}>
            <div className="absolute inset-0 bg-black opacity-40">
              <div className="absolute inset-0 flex items-center justify-start z-10">
                <h4 className="text-newsOverlayColor text-lg sm:text-[20px] md:text-[32px] ps-[16px] sm:ps-[32px] font-bold leading-[48px] font-poppins">
                  Pad printing is a printing  <br />
                  technique used to transfer <br />
                  images onto a variety .....
                </h4>
              </div>
              <div className="flex absolute bottom-[20px] left-[20px] text-white text-[12px] sm:text-[14px]">
                <p className='px-[20px]'>2 Hours Ago</p>
                <p>CNN Indonesia</p>
              </div>
            </div>
          </div>
        </div>

       
        <div className="col-span-1 md:col-span-4 mt-[20px] sm:mt-[20px]">
          <p className='font-poppins text-newsColor text-[16px] sm:text-[20px] md:text-[24px] leading-[40px] font-normal'>
            <span className='text-[20px] sm:text-[24px] font-semibold text-spanColor leading-[50px]'>
              Pad printing,
            </span> is a versatile and precise printing method that excels in transferring images onto complex surfaces, making it an ideal choice for a wide range of industries and applications.
          </p>
        </div>
      </div>

      <div>
        <h2 className='font-poppins text-[32px] sm:text-[28px] md:text-[32px] text-titleColor font-medium pb-[10px]'>Latest News</h2>

        <div className="min-h-screen flex items-center justify-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 pb-6">
         
            {Array(10).fill().map((_, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
                <img
                  src={`./images/news${(index % 5) + 1}.png`} 
                  alt="news image"
                  className="w-full h-48 sm:h-56 object-cover"
                />
                <div className="p-4">
                  <h2 className="text-[20px] sm:text-[18px] md:text-[20px] text-titleNewsColor font-medium">
                    News Title Lorem Ipsum Dolor Sit Amet
                  </h2>
                  <div className="flex text-decsNewsColor text-[12px] sm:text-[14px] font-normal">
                    <p className='pe-[20px]'>1 Hours Ago</p>
                    <p>CNN Indonesia</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  )
}
