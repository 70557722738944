import React, { useState } from 'react';
import { FaTimes } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";

export default function FAQ() {
  
  const [isOpen, setIsOpen] = useState(false);

  
  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex flex-col justify-center items-center h-auto mb-[50px] px-4">
      <h2 className="md:text-4xl  sm:text-2xl font-bold text-center font-poppins mb-4 mt-[50px]">FAQ</h2>
      <div className="p-6 rounded-lg shadow-md w-full max-w-[1050px]">

        <div className="gap-6 mx-auto w-full">
        
          <div className="flex flex-col gap-4 bg-gray-100 p-[32px] sm:p-[48px]">
            <div className="flex items-center gap-4">
              <div className="text-numColor w-8 h-8 flex items-center justify-center rounded-full me-[16px] sm:me-[42px] font-poppins md:text-4xl  sm:text-lg font-medium text-4xl">
                01
              </div>
              <div className="flex-1 md:text-4xl  sm:text-xl font-poppins font-medium text-footerColor">
                What is pad printing?
              </div>
              <div className="cursor-pointer" onClick={toggleAnswer}>
                {isOpen ? <FaTimes size={20} /> : <IoMdAdd size={30} />}
              </div>
            </div>
            {isOpen && (
              <p className="text-answerColor md:text-4xl  sm:text-xl font-poppins ms-[16px] sm:ms-[90px] mt-4">
                A flexible and reliable technique for transferring ink to uneven surfaces, providing precise results on a variety of materials.
              </p>
            )}
          </div>

      
          <div className="flex flex-col gap-4 bg-white p-[32px] sm:p-[48px]">
            <div className="flex items-center gap-4">
              <div className="text-numColor w-8 h-8 flex items-center justify-center rounded-full me-[16px] sm:me-[42px] font-poppins  md:text-4xl  sm:text-lg font-medium text-4xl">
                02
              </div>
              <div className="flex-1 md:text-4xl  sm:text-xl font-poppins font-medium text-footerColor">
                Can I customize the steel clichés?
              </div>
              <div className="cursor-pointer" >
                 <IoMdAdd size={30} />
              </div>
            </div>
            {/* {isOpen && (
              <p className="text-answerColor text-[16px] sm:text-[20px] font-poppins ms-[16px] sm:ms-[90px] mt-4">
                Yes, steel clichés can be fully customized to meet your specific printing needs.
              </p>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}
