import React from 'react'

export default function About() {
  return (
    <>
 
  <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-[20px] mb-[20px] px-4 md:px-10 sm:px-6">
      <div className="col-span-1 md:col-span-6 p-4 md:mt-[0] ">
        <h2 className='text-mainColor font-poppins text-[22px] sm:text-[26px] md:text-[30px] leading-[36px] sm:leading-[42px] font-medium'>
        Printing and Packaging Client
        </h2>
        <p className='text-clientPrColor font-poppins text-[16px] sm:text-[18px] md:text-[22px] leading-[28px] sm:leading-[32px] font-normal'>
        "The precision of the thin steel clichés we ordered has transformed our printing quality. The intricate designs come out perfectly every time, which has significantly improved our product appeal."
        </p>
        <button
          className="px-6 py-2 bg-black text-white rounded-md shadow mt-[40px] flex items-center space-x-2"
        >
          See more
          <span className="text-white ps-[15px]">
            &#187;
          </span>
        </button>
      </div>
      <div className="col-span-1 md:col-span-6 p-4">
        <div className="grid grid-cols-2 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
          <div className="relative bg-cover bg-center w-full h-[180px] sm:w-[302px] sm:h-[321px] rounded-2xl overflow-hidden" style={{
            backgroundImage: "url('./images/package1.png')",
          }}>
            <div className="absolute inset-0 bg-black opacity-20"></div>
          </div>
          <div className="relative bg-cover bg-center w-full h-[180px] sm:w-[302px] sm:h-[321px] rounded-2xl overflow-hidden" style={{
            backgroundImage: "url('./images/package2.png')",
          }}>
            <div className="absolute inset-0 bg-black opacity-20"></div>
          </div>  
          <div className="relative col-span-2 sm:col-span-2 md:col-span-2 bg-cover bg-center w-full h-[180px] sm:w-[636px] sm:h-[213px] rounded-2xl overflow-hidden" style={{
            backgroundImage: "url('./images/package3.png')",
          }}>
            <div className="absolute inset-0 bg-black opacity-20"></div>
          </div>

        </div>
      </div>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-[20px] mb-[20px] px-4 md:px-10 sm:px-6">
      <div className="col-span-1 md:col-span-6 p-4  md:mt-[0]">
        <h2 className='text-mainColor font-poppins text-[22px] sm:text-[26px] md:text-[30px] leading-[36px] sm:leading-[42px] font-medium'>
        Textile Manufacturer
        </h2>
        <p className='text-clientPrColor font-poppins text-[16px] sm:text-[18px] md:text-[22px] leading-[28px] sm:leading-[32px] font-normal'>
        "We’ve been using the thick steel clichés for our textile printing, and the results are fantastic. The durability and detail have helped us maintain our high standards and meet customer expectations."
        </p>
        <button
          className="px-6 py-2 bg-black text-white rounded-md shadow mt-[40px] flex items-center space-x-2"
        >
        See more
          <span className="text-white ps-[15px]">
            &#187;
          </span>
        </button>
      </div>
      <div className="col-span-1 md:col-span-6 p-4">
        <div className="grid grid-cols-2 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
          <div className="relative bg-cover bg-center w-full h-[180px] sm:w-[302px] sm:h-[321px] rounded-2xl overflow-hidden" style={{
            backgroundImage: "url('./images/textile1.png')",
          }}>
            <div className="absolute inset-0 bg-black opacity-20"></div>
          </div>
          <div className="relative bg-cover bg-center w-full h-[180px] sm:w-[302px] sm:h-[321px] rounded-2xl overflow-hidden" style={{
            backgroundImage: "url('./images/textile2.png')",
          }}>
            <div className="absolute inset-0 bg-black opacity-20"></div>
          </div>
          <div className="relative col-span-2 sm:col-span-2 md:col-span-2 bg-cover bg-center w-full h-[180px] sm:w-[636px] sm:h-[213px] rounded-2xl overflow-hidden" style={{
            backgroundImage: "url('./images/textile3.png')",
          }}>
            <div className="absolute inset-0 bg-black opacity-20"></div>
          </div>

        </div>
      </div>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-[20px] mb-[20px] px-4 md:px-10 sm:px-6">
      <div className="col-span-1 md:col-span-6 p-4  md:mt-[0] ">
        <h2 className='text-mainColor font-poppins text-[22px] sm:text-[26px] md:text-[30px] leading-[36px] sm:leading-[42px] font-medium'>
        Pharmaceutical Company
        </h2>
        <p className='text-clientPrColor font-poppins text-[16px] sm:text-[18px] md:text-[22px] leading-[28px] sm:leading-[32px] font-normal'>
        "We rely on high-quality printing for compliance, and the clichés we received have exceeded our expectations. They ensure that all necessary information is printed clearly and legibly."        </p>
        <button
          className="px-6 py-2 bg-black text-white rounded-md shadow mt-[40px] flex items-center space-x-2"
        >
         See more
          <span className="text-white ps-[15px]">
            &#187;
          </span>
        </button>
      </div>
      <div className="col-span-1 md:col-span-6 p-4">
        <div className="grid grid-cols-2 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
          <div className="relative bg-cover bg-center w-full h-[180px] sm:w-[302px] sm:h-[321px] rounded-2xl overflow-hidden" style={{
            backgroundImage: "url('./images/pharma1.png')",
          }}>
            <div className="absolute inset-0 bg-black opacity-20"></div>
          </div> 
          <div className="relative bg-cover bg-center w-full h-[180px] sm:w-[302px] sm:h-[321px] rounded-2xl overflow-hidden" style={{
            backgroundImage: "url('./images/pharma2.png')",
          }}>
            <div className="absolute inset-0 bg-black opacity-20"></div>
          </div>
          <div className="relative col-span-2 sm:col-span-2 md:col-span-2 bg-cover bg-center w-full h-[180px] sm:w-[636px] sm:h-[213px] rounded-2xl overflow-hidden" style={{
            backgroundImage: "url('./images/pharma3.png')",
          }}>
            <div className="absolute inset-0 bg-black opacity-20"></div>
          </div>
        </div>
      </div>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-[20px] mb-[20px] px-4 md:px-10 sm:px-6">
    <div className="col-span-1 md:col-span-6 p-4  md:mt-[0] ">
      <h2 className='text-mainColor font-poppins text-[22px] sm:text-[26px] md:text-[30px] leading-[36px] sm:leading-[42px] font-medium'>
        Client Testimonials
      </h2>
      <p className='text-clientPrColor font-poppins text-[16px] sm:text-[18px] md:text-[22px] leading-[28px] sm:leading-[32px] font-normal'>
        Clients across various industries consistently praise our products for their quality, durability, and the customization options available. Their testimonials reflect our commitment to excellence and our ability to meet the diverse needs of our partners.
      </p>
      {/* <button
        className="px-6 py-2 bg-black text-white rounded-md shadow mt-[40px] flex items-center space-x-2"
      >
        Check All
        <span className="text-white ps-[15px]">
          &#187;
        </span>
      </button> */}
    </div>
    {/* <div className="col-span-1 md:col-span-6 p-4">
      <div className="grid grid-cols-2 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
        <div className="relative bg-cover bg-center w-full h-[180px] sm:w-[302px] sm:h-[321px] rounded-2xl overflow-hidden" style={{
          backgroundImage: "url('./images/client1.png')",
        }}>
          <div className="absolute inset-0 bg-black opacity-20"></div>
        </div>
        <div className="relative bg-cover bg-center w-full h-[180px] sm:w-[302px] sm:h-[321px] rounded-2xl overflow-hidden" style={{
          backgroundImage: "url('./images/client2.png')",
        }}>
          <div className="absolute inset-0 bg-black opacity-20"></div>
        </div>
        <div className="relative col-span-2 sm:col-span-2 md:col-span-2 bg-cover bg-center w-full h-[180px] sm:w-[636px] sm:h-[213px] rounded-2xl overflow-hidden" style={{
          backgroundImage: "url('./images/client3.png')",
        }}>
          <div className="absolute inset-0 bg-black opacity-20"></div>
        </div>
      </div>
    </div> */}
  </div>
  </>
  )
}
