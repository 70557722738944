import React from 'react'
import BackgroundSection from '../Background/Background.jsx';
import Products from '../Products/Products.jsx'
import Services from '../Services/Services.jsx'
import IndustriesWeServe from '../IndustriesWeServe/IndustriesWeServe.jsx'
import ClientTestimonials from '../ClientTestimonials/ClientTestimonials.jsx';
export default function Home() {
  return (
    <>
      <BackgroundSection />
     <div className="container  sm:px-[12px]">
     <Products />
      <Services />
      <IndustriesWeServe />
     </div>
      <ClientTestimonials />
    </>
  )
}




