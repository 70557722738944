
import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-800">
      <h1 className="text-[80px] font-bold">404</h1>
      <h2 className="text-[24px] sm:text-[28px] md:text-[32px] font-medium mb-4">
        Page Not Found
      </h2>
      <p className="text-[16px] sm:text-[18px] text-gray-600 mb-6">
        Sorry, the page you're looking for doesn't exist.
      </p>
      <Link
        to="/"
        className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition duration-300"
      >
        Back to Home
      </Link>
    </div>
  );
}
