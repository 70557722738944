import React from 'react';

export default function IndustriesWeServe() {
  return (
    <div className="container px-4 md:px-10 ">
      <div className="md:ms-[100px] sm:ms-[20px] mt-[40px] mb-[60px]">
        <div>
          <h2 className='text-mainColor font-poppins text-[22px] sm:text-[28px] md:text-[30px] lg:text-[34px]'>
            Industries We Serve
          </h2>
          <p className='text-secondColor font-poppins text-[16px] sm:text-[18px] md:text-[22px] lg:text-[24px]'>
            Our company serves a diverse range of industries, providing tailored solutions through thin and thick steel clichés. With a focus on quality and customization, we meet the unique needs of each sector, ensuring that our clients can achieve their branding and operational goals effectively.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[40px] sm:gap-[60px] mt-[30px]">

        
          <div className="text-center rounded-md overflow-hidden">
            <div className="relative w-full h-[220px] sm:w-[371px] sm:h-[324px] bg-cover bg-center"
              style={{ backgroundImage: "url('./images/ring_ceramic.png')" }}>
              <div className="absolute inset-0 bg-black opacity-40">
                <div className="absolute inset-0 flex items-center justify-center z-10">
                  <h4 className="text-white text-lg sm:text-[22px] font-medium text-[18px] leading-[38px] tracking-[-0.35px] font-poppins">
                    Ring Ceramic
                  </h4>
                </div>
              </div>
            </div>
          </div>


          <div className="text-center rounded-md overflow-hidden">
            <div className="relative w-full h-[220px] sm:w-[371px] sm:h-[324px] bg-cover bg-center"
              style={{ backgroundImage: "url('./images/doctor_blades.jpg')" }}>
              <div className="absolute inset-0 bg-black opacity-40">
                <div className="absolute inset-0 flex items-center justify-center z-10">
                  <h4 className="text-white text-lg sm:text-[22px] font-medium text-[18px] leading-[38px] tracking-[-0.35px] font-poppins">
                   Doctor Blades
                  </h4>
                </div>
              </div>
            </div>
          </div>

{/*        
          <div className="text-center rounded-md overflow-hidden">
            <div className="relative w-full h-[220px] sm:w-[371px] sm:h-[324px] bg-cover bg-center"
              style={{ backgroundImage: "url('./images/ring_ceramic2.png')" }}>
              <div className="absolute inset-0 bg-black opacity-40">
                <div className="absolute inset-0 flex items-center justify-center z-10">
                  <h4 className="text-white text-lg sm:text-[22px] font-medium text-[18px] leading-[38px] tracking-[-0.35px] font-poppins">
                  Ring Ceramic
                  </h4>
                </div>
              </div>
            </div>
          </div> */}

          {/* Optionally add more industries */}
          {/* <div className="text-center">
            <div className="relative w-full sm:w-[371px] sm:h-[324px] bg-cover bg-center"
              style={{ backgroundImage: "url('./images/indust4.png')" }}>
              <div className="absolute inset-0 bg-black opacity-20">
                <div className="absolute inset-0 flex items-center justify-center z-10">
                  <h4 className="text-white text-lg font-medium text-[22px] leading-[38px] tracking-[-0.35px] font-poppins">
                    Advertising Industry
                  </h4>
                </div>
              </div>
            </div>
          </div> */}
          
        </div>
      </div>
    </div>
  );
}
