import './App.css';
import './index.css';
import React from 'react'
import Layout from './Components/Layout/Layout';
import Home from './Components/Home/Home';
import Services from './Components/Services/Services';
import IndustriesWeServe from './Components/IndustriesWeServe/IndustriesWeServe';
import News from './Components/News/News';
import ClientTestimonials from './Components/ClientTestimonials/ClientTestimonials';
import ContactUs from './Components/ContactUs/ContactUs';
import FAQ from './Components/FAQ/FAQ';
import Products from './Components/Products/Products';
import About from './Components/About/About';
import NotFound from './Components/NotFound/NotFound';
import { createBrowserRouter, RouterProvider } from "react-router-dom";


export default function App() {
  let router = createBrowserRouter([
    {
      path: "", element: <Layout />, children: [
        { path: "services", element: <Services /> },
        { path: "industriesweserve", element: <IndustriesWeServe /> },
        { path: "news", element: <News /> },
        { path: "products", element: < Products /> },
        { path: "clienttestimonials", element: <ClientTestimonials /> },
        { path: "contact", element: <ContactUs /> },
        { path: "faq", element: <FAQ /> },
        { path: "about", element: <About /> },
        { index: true, element: <Home /> },

        { path: "*", element: <NotFound /> }
      ]
    }
  ])
  return (
    <>
      <RouterProvider router={router}></RouterProvider>


    </>
  )
}
