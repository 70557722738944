import React from 'react'

export default function ClientTestimonials() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-[20px] mb-[120px] px-4 md:px-10 sm:px-6">
    
      <div className="col-span-1 md:col-span-6 p-4  md:mt-[0] ml-[35px]">
        <h2 className='text-mainColor font-poppins text-[22px] sm:text-[26px] md:text-[30px] leading-[36px] sm:leading-[42px] font-medium'>
          Client Testimonials
        </h2>
        <p className='text-clientPrColor font-poppins text-[16px] sm:text-[18px] md:text-[22px] leading-[28px] sm:leading-[32px] font-normal'>
          Clients across various industries consistently praise our products for their quality, durability, and the customization options available. Their testimonials reflect our commitment to excellence and our ability to meet the diverse needs of our partners.
        </p>
        {/* <button
          className="px-6 py-2 bg-black text-white rounded-md shadow mt-[40px] flex items-center space-x-2"
        >
          Check All
          <span className="text-white ps-[15px]">
            &#187;
          </span>
        </button> */}
      </div>

  
      {/* <div className="col-span-1 md:col-span-6 p-4">
        <div className="grid grid-cols-2 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">

          
          <div className="relative bg-cover bg-center w-full h-[180px] sm:w-[302px] sm:h-[321px] rounded-2xl overflow-hidden" style={{
            backgroundImage: "url('./images/client1.png')",
          }}>
            <div className="absolute inset-0 bg-black opacity-20"></div>
          </div>

        
          <div className="relative bg-cover bg-center w-full h-[180px] sm:w-[302px] sm:h-[321px] rounded-2xl overflow-hidden" style={{
            backgroundImage: "url('./images/client2.png')",
          }}>
            <div className="absolute inset-0 bg-black opacity-20"></div>
          </div>

        
          <div className="relative col-span-2 sm:col-span-2 md:col-span-2 bg-cover bg-center w-full h-[180px] sm:w-[636px] sm:h-[213px] rounded-2xl overflow-hidden" style={{
            backgroundImage: "url('./images/client3.png')",
          }}>
            <div className="absolute inset-0 bg-black opacity-20"></div>
          </div>

        </div>
      </div> */}
    </div>
  )
}
