import React from 'react'

export default function Services() {
  return (
    <>
      <div className="container px-4 md:px-10">
        <div className='md:ms-[100px] mt-[30px]  mb-[60px] sm:ms-[20px]'>
          <div>
            <h2 className='text-mainColor font-poppins text-[30px] sm:text-[28px] md:text-[30px]'>
              Services
            </h2>
            <p className='text-secondColor font-poppins text-[16px] sm:text-[18px] md:text-[22px]'>
              1- Pad Printing : A versatile printing technique used for marking on irregular surfaces such as plastic, metal, glass, and more.
              <br />2- Applications : Commonly used for printing on small items, such as electronic components, promotional items, and custom logos on various products.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[20px] sm:gap-[30px] mt-[30px]">

            <div className="text-center rounded-md overflow-hidden">
              <div
                className="relative w-full h-[180px] sm:w-[280px] sm:h-[240px] md:w-[371px] md:h-[324px] bg-cover bg-center"
                style={{
                  backgroundImage: "url('./images/pad_printing_inks.png')",
                }}
              >
                <div className="absolute inset-0 bg-black opacity-20"></div>
              </div>
            </div>

            <div className="text-center rounded-md overflow-hidden">
              <div
                className="relative w-full h-[180px] sm:w-[280px] sm:h-[240px] md:w-[371px] md:h-[324px] bg-cover bg-center"
                style={{
                  backgroundImage: "url('./images/pad_printing.png')",
                }}
              >
                <div className="absolute inset-0 bg-black opacity-40"></div>
              </div>
            </div>

            <div className="text-center rounded-md overflow-hidden">
              <div
                className="relative w-full h-[180px] sm:w-[280px] sm:h-[240px] md:w-[371px] md:h-[324px] bg-cover bg-center"
                style={{
                  backgroundImage: "url('./images/pads_for_printing.png')",
                }}
              >
                <div className="absolute inset-0 bg-black opacity-20"></div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
