import React from 'react'

export default function Products() {
  return (
    <>
      <div className="container px-4 md:px-10 ">
        <div className='mt-[40px] md:ms-[100px] sm:ms-[20px] '>
          <div>
            <h2 className='text-mainColor font-poppins text-[22px] sm:text-[28px] md:text-[30px] lg:text-[34px]'>
              Products
            </h2>
            <p className='text-secondColor font-poppins text-[16px] sm:text-[18px] md:text-[22px] lg:text-[24px]'>
              This company provides a comprehensive range of steel clichés, catering to both standard and customized needs. Their products are engineered for precision, durability, and versatility, making them ideal for various industrial applications.
            </p>

            <ul className='flex flex-wrap justify-between mt-4'>
              <li className='text-secondColor font-poppins text-[16px] sm:text-[18px] md:text-[21px] lg:text-[22px] mr-[40px]'>
                1 - Thin Steel Clichés
              </li>
              <li className='text-secondColor font-poppins text-[16px] sm:text-[18px] md:text-[21px] lg:text-[22px] mr-[40px]'>
                2 - Thick Steel Clichés
              </li>
              <li className='text-secondColor font-poppins text-[16px] sm:text-[18px] md:text-[21px] lg:text-[22px]'>
                3 - Customization
              </li>
            </ul>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[40px] sm:gap-[60px] mt-[30px]">

            
            <div className="text-center rounded-md overflow-hidden">
              <div
                className="relative w-full h-[220px] sm:w-[371px] sm:h-[324px] bg-cover bg-center"
                style={{ backgroundImage: "url('./images/thin.png')" }}>
                <div className="absolute inset-0 bg-black opacity-40"></div>
              </div>
              <div>
                <h3 className='text-titleColor font-poppins text-[20px] sm:text-[22px] md:text-[24px] lg:text-[26px]'>
                  Thin Steel Clichés
                </h3>
              </div>
            </div>

         
            <div className="text-center rounded-md overflow-hidden">
              <div
                className="relative w-full h-[220px] sm:w-[371px] sm:h-[324px] bg-cover bg-center"
                style={{ backgroundImage: "url('./images/thick.png')" }}>
                <div className="absolute inset-0 bg-black opacity-40"></div>
              </div>
              <div>
                <h3 className='text-titleColor font-poppins text-[20px] sm:text-[22px] md:text-[24px] lg:text-[26px]'>
                  Thick Steel Clichés
                </h3>
              </div>
            </div>

         
            <div className="text-center rounded-md overflow-hidden">
              <div
                className="relative w-full h-[220px] sm:w-[371px] sm:h-[324px] bg-cover bg-center"
                style={{ backgroundImage: "url('./images/customization.png')" }}>
                <div className="absolute inset-0 bg-black opacity-20"></div>
              </div>
              <div>
                <h3 className='text-titleColor font-poppins text-[20px] sm:text-[22px] md:text-[24px] lg:text-[26px]'>
                  Customization
                </h3>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
  

// import React, { useState, useRef } from 'react';

// export default function Products() {
//   const slides = [
//     {
//       id: 1,
//       title: "Thin Steel Clichés",
//       image: "./images/thin.png",
//     },
//     {
//       id: 2,
//       title: "Thick Steel Clichés",
//       image: "./images/thick.png",
//     },
//     {
//       id: 3,
//       title: "Customization",
//       image: "./images/customization.png",
//     },
//   ];

//   const [currentIndex, setCurrentIndex] = useState(0);
//   const sliderRef = useRef();

//   const nextSlide = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
//   };

//   const prevSlide = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? slides.length - 1 : prevIndex - 1
//     );
//   };

//   return (
//     <>
//       <div className="container px-4 md:px-10">
//         <div className='mt-[40px] md:ms-[100px] sm:ms-[20px]'>
//           <div>
//             <h2 className='text-mainColor font-poppins text-[22px] sm:text-[28px] md:text-[30px] lg:text-[34px]'>
//               Products
//             </h2>
//             <p className='text-secondColor font-poppins text-[16px] sm:text-[18px] md:text-[22px] lg:text-[24px]'>
//               This company provides a comprehensive range of steel clichés, catering to both standard and customized needs. Their products are engineered for precision, durability, and versatility, making them ideal for various industrial applications.
//             </p>
//           </div>

//           {/* Slider */}
//           <div className="relative mt-[30px] overflow-hidden" ref={sliderRef}>
//             <div
//               className="flex transition-transform duration-500"
//               style={{
//                 transform: `translateX(-${currentIndex * 100}%)`,
//                 width: `${slides.length * 100}%`,
//               }}
//             >
//               {slides.map((slide) => (
//                 <div
//                   key={slide.id}
//                   className="flex-shrink-0 w-full md:w-1/3 px-4"
//                   style={{ width: "100%" }}
//                 >
//                   <div className="text-center rounded-md overflow-hidden">
//                     <div
//                       className="relative w-full h-[220px] sm:w-[371px] sm:h-[324px] bg-cover bg-center"
//                       style={{ backgroundImage: `url(${slide.image})` }}
//                     >
//                       <div className="absolute inset-0 bg-black opacity-40"></div>
//                     </div>
//                     <div>
//                       <h3 className='text-titleColor font-poppins text-[20px] sm:text-[22px] md:text-[24px] lg:text-[26px]'>
//                         {slide.title}
//                       </h3>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>

//             {/* Navigation */}
//             <button
//               onClick={prevSlide}
//               className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-md"
//             >
//               &lt;
//             </button>
//             <button
//               onClick={nextSlide}
//               className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-md"
//             >
//               &gt;
//             </button>
//           </div>

//           {/* Dots */}
//           <div className="flex justify-center mt-4 space-x-2">
//             {slides.map((_, index) => (
//               <div
//                 key={index}
//                 onClick={() => setCurrentIndex(index)}
//                 className={`w-3 h-3 rounded-full cursor-pointer ${
//                   index === currentIndex ? "bg-mainColor" : "bg-gray-400"
//                 }`}
//               ></div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
