import React from 'react'
import { Link } from 'react-router-dom'
import { SiFacebook } from "react-icons/si";
import { SiLinkedin } from "react-icons/si";
import { FaWhatsapp } from 'react-icons/fa6';

export default function ContactUs() {
  return (
    <div className="contact-container flex flex-col items-center justify-center min-h-screen bg-gray-50 p-3">
      <header className="text-center mb-8">
        <h1 className="text-3xl font-bold">Contact Us</h1>
      </header>

      <div className="flex flex-col justify-center items-center w-full max-w-lg p-6 border border-gray-300 rounded-lg shadow-lg bg-white">
        
        {/* E-mail Support Section */}
        <section className="email-support mb-6 flex items-center gap-4 w-full border-b border-gray-300 pb-6">
          <div className="icon">
            <i className="fa-regular fa-envelope-open md:text-[38px] sm:text-[32px]"></i>
          </div>
          <div>
            <h2 className="md:text-2xl  sm:text-xl font-semibold">E-mail Support</h2>
            <Link to="mailto:info@misrprinting.com" className="text-lg hover:underline ">
              info@misrprinting.com
            </Link>
          </div>
        </section>

        {/* Phone Support Section */}
        <section className="phone-support mb-6 flex items-center gap-4 w-full border-b border-gray-300 pb-6">
          <div className="icon">
            <i className="fa-solid fa-phone md:text-[38px] sm:text-[32px] "></i>
          </div>
          <div>
            <h2 className="md:text-2xl  sm:text-xl font-semibold">Phone Support</h2>
            <Link to="tel:+201005178711" className="text-lg hover:underline ">
              +201005178711
            </Link>
          </div>
        </section>
        <section className="social-media w-full text-center">
          <h3 className="md:text-2xl  sm:text-xl font-semibold mb-4">Follow Us</h3>
          <ul className="flex space-x-[35px] items-center justify-center">
    <li>
      <Link  to="https://www.facebook.com/mostafa.mahmoud.56614?mibextid=LQQJ4d&rdid=ihLt2WNQKrj7qdQu&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F18Qa93CUWN%2F%3Fmibextid%3DLQQJ4d" target="_blank">
        <SiFacebook className="text-blue-600 hover:text-blue-800" />
      </Link>
    </li>
    <li>
      <Link  to="https://www.linkedin.com/in/mostafa-darwish-4b9198241/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank">
        <SiLinkedin className="text-blue-700 hover:text-blue-900" />
      </Link>
    </li>
    <li>
  <a href="https://wa.me/+201005178711" target="_blank" rel="noopener noreferrer">
    <FaWhatsapp className="text-green-500 hover:text-green-600" />
  </a>
</li>

  </ul>
        </section>

      </div>
    </div>
  );
}
